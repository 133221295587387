import { Store, StoreTag } from "./Store";
import User from "./User";
import { FilterBy } from "../features/ManageLead/services";
import TenantBusiness from "./TenantBusiness";

export type AdditionalField = {
  reportName: string;
  fieldName: string;
  level: string;
};

export type AdditionalFieldProduct = {
  label: string;
  type: string;
  value: string;
};

export enum ReportTypeField {
  TRANSACTION = "transaction",
  ENROLL = "enroll",
}

export const ReportTypeFieldData: Record<ReportTypeField, string> = {
  [ReportTypeField.TRANSACTION]: "Transaction Wise",
  [ReportTypeField.ENROLL]: "Enrollment Wise",
};

export enum ReportTypeLoyalty {
  CUSTOMER = "customer",
  STORE = "store",
  OVER_ALL = "overall",
  BILL = "bill",
  SKU = "sku",
  EXPIRY = "expiry",
}

export const ReportTypeLoyaltyData: Record<ReportTypeLoyalty, string> = {
  [ReportTypeLoyalty.CUSTOMER]: "Customer",
  [ReportTypeLoyalty.STORE]: "Store",
  [ReportTypeLoyalty.OVER_ALL]: "Over All",
  [ReportTypeLoyalty.BILL]: "Bill",
  [ReportTypeLoyalty.SKU]: "SKU",
  [ReportTypeLoyalty.EXPIRY]: "Expiry",
};

export enum ReportTypeKPI {
  ANNUAL = "annual",
  DEFAULT = "default",
  QUARTERLY = "quarterly",
  DAILY = "daily",
}

export const ReportTypeKPIData: Record<ReportTypeKPI, string> = {
  [ReportTypeKPI.ANNUAL]: "Annual",
  [ReportTypeKPI.DEFAULT]: "Monthly",
  [ReportTypeKPI.QUARTERLY]: "Quarterly",
  [ReportTypeKPI.DAILY]: "Daily",
};

export enum GroupingColumn {
  RFM = "RFM",
  Tier = "Tier",
}

export const ReportTypeOfferData: Record<GroupingColumn, string> = {
  [GroupingColumn.RFM]: "RFM",
  [GroupingColumn.Tier]: "Tier",
};

export enum GroupingMonth {
  THREE = "3",
  SIX = "6",
  TWELVE = "12",
}
export enum TagBoolean {
  YES = "Yes",
  NO = "No",
}

export enum AdditionalFeaturesType {
  LOYALTY_EARN_BURN = "loyaltyEarnBurn",
  PURCHASE_AND_VISIT_ANALYSIS = "purchaseAndVisitAnalysis",
  MRP_ANALYSIS = "mrpAnalysis",
  HIGHEST_BILL = "highestBill",
  BEST_PRODUCT = "bestProduct",
}
export const AdditionalFeaturesLabelMap: Record<
  AdditionalFeaturesType,
  string
> = {
  [AdditionalFeaturesType.LOYALTY_EARN_BURN]: "Loyalty Earn Burn",
  [AdditionalFeaturesType.PURCHASE_AND_VISIT_ANALYSIS]:
    "Purchase and Visits analysis",
  [AdditionalFeaturesType.MRP_ANALYSIS]: "MRP analysis",
  [AdditionalFeaturesType.HIGHEST_BILL]: "Highest bill details",
  [AdditionalFeaturesType.BEST_PRODUCT]: "Best Product",
};
export interface QuerySegmentType {
  key: string;
  name: string;
}

export interface BillReport {
  dateFilter?: {
    filterBy: FilterBy.CREATED_DATE;
    startDate: string;
    endDate: string;
  };
  leadDateFilter?: {
    isFollowUp: boolean;
    startDate: string;
    endDate: string;
  }
  businessId?: number;
  additionalFields?: AdditionalField[];
  productAdditionalFields?: AdditionalFieldProduct[];
  type?: string;
  billCount?: number;
  billValue?: number;
  pointSpend?: number;
  stores?: Store[];
  storeTags?: StoreTag[];
  owners?: User[];
  sources?: string[];
  reportType?: ReportTypeField;
  segment?: GroupingColumn;
  monthCount?: GroupingMonth;
  business?: TenantBusiness[];
  querySegment?: QuerySegmentType[];
  date?: string;
  endDate?: string;
  singleStoreTags?: StoreTag[];
  singleStore?: string;
  multiStores?: Store[];
  isUsingTags?: TagBoolean;
  additionalFeatures?: AdditionalFeaturesType[];
}

export interface Pagination {
  page: number;
  rowsPerPage: number;
}

export enum ReportTypes {
  STORE_HEALTH_REPORT = "store_health_report",
  STORE_KPI_REPORT = "store_kpi_report",
  TRANSACTION = "transaction",
  FRAUD_REPORT = "fraud_report",
  MEMBER_BILL_REPORT = "member_bill_report",
  // MEMBER_LOYALTY_REPORT = "member_loyalty_report",
  CAMPAIGN_REPORT = "campaign_report",
  STORE_STATUS_ACTIVITY = "store_status_activity",
  OVERALL_LEAD_REPORT = "overall_lead_report",
  STORE_CATEGORY_REPORT = "store_category_report",
  STORE_EFFICIENCY_REPORT = "store_efficiency_report",
  STORE_PERSON_EFFICIENCY_REPORT = "store_person_efficiency_report",
  SOURCE_EFFICIENCY_REPORT = "source_efficiency_report",
  PRODUCT_HIERARCHY_EFFICIENCY_REPORT = "product_hierarchy_efficiency_report",
  OFFER_REPORT = "offer_report",
  OFFER_REDEMPTION_REPORT = "offer_redemption_report",
  LIABILITY_SUMMARY_REPORT = "liability_summary_report",
  KPI_REPORT = "kpi_report",
}
